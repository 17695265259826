import ReactGA from 'react-ga4';

const MEASUREMENT_ID = 'G-0HSX7K9RTF';

// Session storage for viewed pages
const viewedPages = new Set<string>();

// Debounce utility
const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Types for analytics events
interface AnalyticsEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  [key: string]: any;
}

export const Analytics = {
  initialize: () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(MEASUREMENT_ID, {
        gaOptions: {
          siteSpeedSampleRate: 100
        }
      });
    }
  },

  pageview: debounce((path: string) => {
    if (process.env.NODE_ENV === 'production') {
      const normalizedPath = path.toLowerCase().replace(/\/+$/, '');
      
      if (!viewedPages.has(normalizedPath)) {
        ReactGA.send({
          hitType: "pageview",
          page: normalizedPath,
          title: document.title
        });
        viewedPages.add(normalizedPath);
      }
    } else {
      console.log('Development - Page View:', path);
    }
  }, 1000),

  // User engagement tracking
  logEngagement: {
    venueInteraction: (
      action: 'view' | 'visit' | 'unmark',
      venueId: number,
      venueName: string,
      venueType: string
    ) => {
      Analytics.logEvent('Venue Interaction', action, {
        venue_id: venueId,
        venue_name: venueName,
        venue_type: venueType
      });
    },

    shareContent: (contentType: string, success: boolean) => {
      Analytics.logEvent('Share', success ? 'Share Success' : 'Share Failure', {
        content_type: contentType
      });
    },

    filter: (filterType: string, value: string) => {
      Analytics.logEvent('Filter', 'Apply Filter', {
        filter_type: filterType,
        filter_value: value
      });
    }
  },

  // Authentication events
  logAuth: {
    signIn: (method: string, success: boolean) => {
      Analytics.logEvent('Authentication', success ? 'Sign In Success' : 'Sign In Failure', {
        auth_method: method
      });
    },

    signOut: () => {
      Analytics.logEvent('Authentication', 'Sign Out');
      // Clear viewed pages on sign out
      viewedPages.clear();
    },

    deleteAccount: () => {
      Analytics.logEvent('Authentication', 'Delete Account');
    }
  },

  // Core event tracking
  logEvent: (category: string, action: string, data?: any) => {
    if (process.env.NODE_ENV === 'production') {
      const event: AnalyticsEvent = {
        category,
        action,
        ...(data && {
          label: typeof data === 'string' ? data : undefined,
          value: typeof data === 'number' ? data : undefined,
          ...data
        })
      };

      ReactGA.event(event);
    } else {
      console.log('Development - Event:', { category, action, data });
    }
  },

  // Error tracking
  logError: (
    errorType: string,
    errorMessage: string,
    severity: 'low' | 'medium' | 'high' = 'medium'
  ) => {
    Analytics.logEvent('Error', errorType, {
      error_message: errorMessage,
      error_severity: severity,
      page_url: window.location.href,
      timestamp: new Date().toISOString()
    });
  }
};

export default Analytics;