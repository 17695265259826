import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link, useLocation, Navigate } from 'react-router-dom';
import { UserProfile, fetchUserProfile } from './services/api';
import HomePage from './components/HomePage';
import VenuePage from './components/VenuePage';
import UserDashboardPage from './components/UserDashboardPage';
import Login from './components/Login';
import AnimatedBackground from './components/AnimatedBackground';
import Footer from './components/Footer';
import PrivacyPolicy from './components/Footer/PrivacyPolicy';
import TermsOfService from './components/Footer/TermsOfService';
import { User, ChevronDown, LogOut, Trash2 } from 'lucide-react';
import Logo from './components/Logo';
import DeleteAccountModal from './components/DeleteAccountModal';
import { deleteUserAccount } from './services/api';
import UserMenu from './components/UserMenu';
import Analytics from './services/analytics';
import AdminDashboard from './components/AdminDashboard';


const HeaderButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate('/')} className="text-2xl sm:text-3xl font-bold text-gray-900">
      <Logo />
    </button>
  );
};

const RouteTracker: React.FC = () => {
  const location = useLocation();
  const previousPath = useRef<string>('');

  useEffect(() => {
    // Only track if it's a new path
    if (location.pathname !== previousPath.current) {
      Analytics.pageview(location.pathname);
      previousPath.current = location.pathname;
    }
  }, [location]);

  return null;
};



const App: React.FC = () => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

    // Initialize Analytics
    useEffect(() => {
      Analytics.initialize();
    }, []);  

  const setUserProfileCallback = useCallback((newProfile: React.SetStateAction<UserProfile | null>) => {
    console.log('Setting new user profile:', newProfile);
    setUserProfile(newProfile);
  }, []);

  useEffect(() => {
    const loadUserProfile = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const profile = await fetchUserProfile();
          setUserProfileCallback(profile);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          localStorage.removeItem('token');
          setUserProfileCallback(null);
        }
      }
    };
    loadUserProfile();
  }, [setUserProfileCallback]);

  const handleLogout = () => {
    Analytics.logAuth.signOut();  // Updated from Analytics.logLogout()
    localStorage.removeItem('token');
    localStorage.removeItem('googleFirstName');
    setUserProfileCallback(null);
  };
  

  return (
    <GoogleOAuthProvider clientId="260065421156-6bor1a5hr1pkmhh42kqus63b6njbcjse.apps.googleusercontent.com">
      <Router>
        <RouteTracker />
        <div className="relative min-h-screen bg-gray-100 flex flex-col">
          <AnimatedBackground />
          <header className="relative z-20 bg-white bg-opacity-90 shadow">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between items-center h-16">
                <HeaderButton />
                <div className="flex items-center space-x-4">
                  {userProfile ? (
                    <UserMenu 
                      userProfile={userProfile}
                      onLogout={handleLogout}
                    />
                  ) : (
                    <Login 
                      key="logged-out"
                      userProfile={null}
                      setUserProfile={setUserProfileCallback} 
                    />
                  )}
                </div>
              </div>
            </div>
          </header>

          <main className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-12 flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route 
                path="/venues/:city" 
                element={<VenuePage userProfile={userProfile} setUserProfile={setUserProfileCallback} />} 
              />
              <Route
                path="/dashboard"
                element={<UserDashboardPage userProfile={userProfile} />}
              />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/dashboard/admin" element={userProfile?.user?.is_superuser ? (
                    <AdminDashboard />
                  ) : (
                    <Navigate to="/" replace />
                  )
                } 
              />
            </Routes>
          </main>

          <Footer />
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;