import React from 'react';
import { X } from 'lucide-react';

interface ImageShareModalProps {
  imageData: string;
  onClose: () => void;
}

const ImageShareModal: React.FC<ImageShareModalProps> = ({ imageData, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-lg w-full overflow-hidden shadow-xl transform transition-all sm:max-w-xl">
        <div className="relative">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
          >
            <X className="w-6 h-6" />
          </button>
          <img
            src={`data:image/png;base64,${imageData}`}
            alt="Share your journey"
            className="w-full h-auto"
          />
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            onClick={() => {
              const link = document.createElement('a');
              link.href = `data:image/png;base64,${imageData}`;
              link.download = 'my-venue-journey.png';
              link.click();
            }}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Download Image
          </button>
          <button
            onClick={onClose}
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageShareModal;