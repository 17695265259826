import axios from 'axios';

// const API_URL = 'http://localhost:8000/api';

// const API_URL = 'http://localhost:8000/api';
// const API_URL = process.env.REACT_APP_API_BASE_URL ? `${process.env.REACT_APP_API_BASE_URL}/api` : 'http://localhost:8000/api';

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
}

const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://eatsy.pro/api'
  : 'http://localhost:8000/api';


export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,  // Add this line
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }
  // Add this line to get CSRF token from cookie
  config.headers['X-CSRFToken'] = getCookie('csrftoken') || '';
  return config;
}, (error) => {
  return Promise.reject(error);
});


api.interceptors.response.use((response) => {
  console.log('API Response:', response);
  return response;
}, (error) => {
  console.error('API Error:', error.response);
  return Promise.reject(error);
});

export interface Publication {
  id: number;
  name: string;
  description: string;
  uses_ranking: boolean;
  uses_michelin_stars: boolean;
  venue_type: 'BOTH' | 'BAR' | 'RESTAURANT';
}

export interface YearlyRating {
  id: number;
  publication: {
    id: number;
    name: string;
    uses_michelin_stars: boolean;
    uses_ranking: boolean;
  };
  year: number;
  michelin_stars: number | null;
  ranking: number | null;
  rating: number | null;
}

export interface Venue {
  id: number;
  name: string;
  description: string;
  image: string;
  venue_type: 'BAR' | 'RESTAURANT';
  country: string;
  city: string;
  yearly_ratings: YearlyRating[];
}

export interface UserProfile {
  id: number;
  user: {
    id: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;
    is_superuser: boolean;
  };
  visited_venues: VenueWithVisitInfo[];
  googleFirstName?: string;
}

export interface City {
  id: number;
  name: string;
  image_url: string;
}

export interface VisitInfo {
  visited_date: string;
}

export interface VenueWithVisitInfo extends Venue {
  visit_info?: VisitInfo;
}

export const fetchVenues = async (publication?: string, year?: number): Promise<Venue[]> => {
  const params: any = {};
  if (publication) params.publication = publication;
  if (year) params.year = year;
  const response = await api.get('/venues/', { params });
  return response.data;
};

export const fetchTopBars = async (): Promise<Venue[]> => {
  const response = await api.get('/venues/top_bars/');
  return response.data;
};

export const fetchTopRestaurants = async (): Promise<Venue[]> => {
  const response = await api.get('/venues/top_restaurants/');
  return response.data;
};

export const toggleVisit = async (venueId: number, date: Date | null): Promise<UserProfile> => {
  try {
    const response = await api.post(`/venues/${venueId}/toggle_visit/`, {
      visited_date: date ? date.toISOString().split('T')[0] : null,
    });
    return response.data as UserProfile;
  } catch (error) {
    console.error('Error in toggleVisit:', error);
    throw error;
  }
};

export const fetchUserProfile = async () => {
  const response = await api.get('/user-profiles/me/');
  const googleFirstName = localStorage.getItem('googleFirstName');
  return {
    ...response.data,
    googleFirstName
  };
};

export const fetchAllVenues = async (): Promise<Venue[]> => {
  const response = await api.get('/venues/');
  return response.data;
};

export const fetchVenuesByCity = async (city: string): Promise<Venue[]> => {
  try {
    const response = await api.get('/venues/');
    const filteredVenues = response.data.filter((venue: Venue) => 
      venue.city.toLowerCase() === city.toLowerCase()
    );
    return filteredVenues;
  } catch (error) {
    console.error('Error fetching venues:', error);
    throw error;
  }
};

export const fetchAllCities = async (): Promise<City[]> => {
  const response = await api.get('/cities/');
  return response.data;
};

export const fetchPublications = async (): Promise<Publication[]> => {
  const response = await api.get('/publications/');
  return response.data;
};

export const authenticateWithGoogle = async (accessToken: string) => {
  const response = await api.post('/auth/google/', { access_token: accessToken });
  const authToken = response.data.key;
  const googleFirstName = response.data.user.user.first_name;
  
  localStorage.setItem('token', authToken);
  localStorage.setItem('googleFirstName', googleFirstName);
  
  const profileResponse = await api.get('/user-profiles/me/');
  
  return {
    token: authToken,
    profile: profileResponse.data,
    googleFirstName
  };
};

export const deleteUserAccount = async (): Promise<void> => {
  await api.delete('/user-profiles/delete_account/');
};

export interface UserStats {
  total_users: number;
  active_users_30d: number;
  monthly_signups: Array<{
    month: string;
    count: number;
  }>;
}

export const fetchUserStats = async (): Promise<UserStats> => {
  const response = await api.get('/user-profiles/statistics/');
  return response.data;
};

