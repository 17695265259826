import React from 'react';
import { FaUtensils} from 'react-icons/fa';
import '../styles/logo.css';

const Logo: React.FC = () => {
  return (
    <div className="logo-container">
      <FaUtensils className="logo-icon" />
      <span className="logo-text">Eatsy</span>
    </div>
  );
};

export default Logo;