import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold mb-8">Terms of Service</h1>
      
      <div className="prose prose-blue max-w-none">
        <p className="mb-6">Last updated: {new Date().toLocaleDateString()}</p>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
          <p>
            By accessing and using Eatsy, you agree to be bound by these Terms of Service and 
            all applicable laws and regulations.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Service Description</h2>
          <p>
            Eatsy is a platform that allows users to track their visits to top restaurants and bars 
            worldwide. We provide information about venues, ratings, and allow users to maintain 
            their personal visit history.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. User Responsibilities</h2>
          <ul className="list-disc pl-6 mb-4">
            <li>Maintain accurate and up-to-date information</li>
            <li>Protect your account credentials</li>
            <li>Use the service in compliance with all applicable laws</li>
            <li>Respect the intellectual property rights of others</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Limitations of Liability</h2>
          <p>
            Eatsy provides venue information on an "as is" basis. We make no warranties about 
            the accuracy or reliability of the information provided.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Changes to Terms</h2>
          <p>
            We reserve the right to modify these terms at any time. Continued use of the service 
            after changes constitutes acceptance of the new terms.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;