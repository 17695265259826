import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, ExternalLink } from 'lucide-react';

const FooterLink: React.FC<{ to: string; external?: boolean; children: React.ReactNode }> = ({ 
  to, 
  external = false, 
  children 
}) => {
  const baseClasses = "inline-flex items-center text-gray-500 hover:text-blue-600 transition-all duration-300";
  
  if (external) {
    return (
      <a 
        href={to}
        className={`${baseClasses} hover:translate-y-[-1px] group`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
        <ExternalLink className="w-3 h-3 ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      </a>
    );
  }
  
  return (
    <Link 
      to={to} 
      className={`${baseClasses} hover:translate-y-[-1px]`}
    >
      {children}
    </Link>
  );
};

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative z-20 border-t border-gray-200 bg-white/95 backdrop-blur-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-4 flex flex-col sm:flex-row justify-between items-center">
          {/* Left section with brand name and copyright */}
          <div className="flex items-center space-x-4 mb-4 sm:mb-0">
            <span className="text-sm text-gray-500">
              Eatsy © {currentYear} All rights reserved
            </span>
          </div>

          {/* Right section with links */}
          <div className="flex items-center space-x-1 sm:space-x-2 text-sm">
            <FooterLink to="/privacy">Privacy</FooterLink>
            <span className="text-gray-300 px-2">•</span>
            <FooterLink to="/terms">Terms</FooterLink>
            <span className="text-gray-300 px-2">•</span>
            <FooterLink to="mailto:eatsypro@gmail.com" external>
              <Mail className="w-4 h-4 mr-1" />
              <span>Contact</span>
            </FooterLink>
          </div>
        </div>
      </div>
      
      {/* Decorative gradient line */}
      <div className="h-[2px] w-full bg-gradient-to-r from-transparent via-blue-500/20 to-transparent" />
    </footer>
  );
};

export default Footer;