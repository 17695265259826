// src/components/UserMenu.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, ChevronDown, LogOut, Trash2, LayoutDashboard } from 'lucide-react';
import { UserProfile } from '../services/api';
import DeleteAccountModal from './DeleteAccountModal';
import { deleteUserAccount } from '../services/api';
import { Analytics } from '../services/analytics';
import { Link } from 'react-router-dom';

interface UserMenuProps {
  userProfile: UserProfile;
  onLogout: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ userProfile, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);




  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    try {
      await deleteUserAccount();
      Analytics.logAuth.deleteAccount();
      onLogout();
      navigate('/');
    } catch (error) {
      Analytics.logError('Account Deletion', 'Failed to delete account', 'high');
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200"
      >
        <span className="whitespace-nowrap">Welcome, {userProfile.googleFirstName || 'User'}</span>
        <ChevronDown className={`w-4 h-4 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 z-50">
          <button
            onClick={() => {
              navigate('/dashboard');
              setIsOpen(false);
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
          >
            <User className="w-4 h-4 mr-2 flex-shrink-0" />
            <span>Dashboard</span>
          </button>

          {userProfile.user.is_superuser && (
            <button
              onClick={() => {
                navigate('/dashboard/admin');
                setIsOpen(false);
              }}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4 mr-2 flex-shrink-0" />
              <span>Admin Dashboard</span>
            </button>
          )}
          <button
            onClick={() => {
              onLogout();
              setIsOpen(false);
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
          >
            <LogOut className="w-4 h-4 mr-2 flex-shrink-0" />
            <span>Logout</span>
          </button>
          <hr className="my-1 border-gray-200" />
          <button
            onClick={() => {
              setShowDeleteModal(true);
              setIsOpen(false);
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100 transition-colors duration-200"
          >
            <Trash2 className="w-4 h-4 mr-2 flex-shrink-0" />
            <span>Delete Account</span>
          </button>
        </div>
      )}

      {showDeleteModal && (
        <DeleteAccountModal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteAccount}
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
};

export default UserMenu;