import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchAllVenues, fetchAllCities, City, Venue } from '../services/api';
import { MapPin } from 'lucide-react';

const HomePage = () => {
  const [cities, setCities] = useState<City[]>([]);
  const [venues, setVenues] = useState<Venue[]>([]);

  useEffect(() => {
    const loadCitiesAndVenues = async () => {
      try {
        const [allCities, allVenues] = await Promise.all([fetchAllCities(), fetchAllVenues()]);
        setVenues(allVenues);
        const citiesWithVenues = allCities.filter(city => 
          allVenues.some(venue => venue.city.toLowerCase() === city.name.toLowerCase())
        );
        setCities(citiesWithVenues);
      } catch (error) {
        console.error('Error loading cities and venues:', error);
      }
    };
    loadCitiesAndVenues();
  }, []);

  const getVenueCounts = (cityName: string) => {
    const cityVenues = venues.filter(venue => venue.city.toLowerCase() === cityName.toLowerCase());
    const restaurantCount = cityVenues.filter(venue => venue.venue_type === 'RESTAURANT').length;
    const barCount = cityVenues.filter(venue => venue.venue_type === 'BAR').length;
    return { restaurantCount, barCount };
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">
        Explore Top Venues by City
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {cities.map((city) => {
          const { restaurantCount, barCount } = getVenueCounts(city.name);
          return (
            <Link
              key={city.id}
              to={`/venues/${city.name}`}
              className="group bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg"
            >
              <div className="relative h-48 overflow-hidden">
                <img 
                  src={city.image_url || '/path/to/default-city-image.jpg'} 
                  alt={city.name}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent">
                  <div className="absolute bottom-0 left-0 p-4">
                    <h2 className="text-xl font-semibold text-white mb-1">{city.name}</h2>
                    <div className="flex items-center text-white/80">
                      <MapPin size={16} className="mr-1" />
                      <span className="text-sm">Explore venues</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center text-sm">
                  <div>
                    <span className="font-medium text-gray-600">{restaurantCount}</span>
                    <span className="text-gray-500 ml-1">Top Restaurants</span>
                  </div>
                  <div>
                    <span className="font-medium text-gray-600">{barCount}</span>
                    <span className="text-gray-500 ml-1">Top Bars</span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default HomePage;