import React, { useState, useEffect } from 'react';
import { Venue, UserProfile } from '../services/api';
import { Award, Martini, Star, Filter, MapPin, Share2 } from 'lucide-react';
import axios from 'axios';
import ImageShareModal from './ImageShareModal';
import ShareOptionsModal from './ShareOptionsModal';
import { api } from '../services/api';

interface UserDashboardProps {
  userProfile: UserProfile | null;
}

const StatCard: React.FC<{ icon: React.ReactNode; title: string; value: number | string }> = ({ icon, title, value }) => (
  <div className="bg-gray-50 rounded-lg p-3 sm:p-4 flex items-center shadow-sm hover:shadow-md transition-shadow duration-300">
    <div className="mr-3 sm:mr-4 text-blue-500">{icon}</div>
    <div>
      <h3 className="text-lg sm:text-xl font-semibold text-gray-800">{value}</h3>
      <p className="text-xs sm:text-sm text-gray-600">{title}</p>
    </div>
  </div>
);

const VenueCard: React.FC<{ venue: Venue }> = ({ venue }) => {
  const maxMichelinStars = venue.yearly_ratings
    .filter(rating => rating.publication.uses_michelin_stars)
    .reduce((max, rating) => Math.max(max, rating.michelin_stars || 0), 0);

  return (
    <div className="bg-gray-50 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
      <div className="h-24 sm:h-32 overflow-hidden">
        <img 
          src={venue.image || '/path/to/default-image.jpg'} 
          alt={venue.name}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-2 sm:p-4">
        <h4 className="text-sm sm:text-base font-semibold mb-1 truncate text-gray-800">{venue.name}</h4>
        <p className="text-xs sm:text-sm text-gray-600 mb-2">{venue.venue_type}</p>
        {maxMichelinStars > 0 && (
          <p className="text-xs sm:text-sm text-yellow-500">
            {[...Array(maxMichelinStars)].map((_, i) => (
              <Star key={i} className="w-3 h-3 sm:w-4 sm:h-4 inline-block fill-current" />
            ))}
          </p>
        )}
      </div>
    </div>
  );
};

const ShareImage: React.FC = () => {
  const [imageData, setImageData] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);

  const handleShareClick = () => {
    setShowOptionsModal(true);
  };

  const handleShareOption = async (option: 'restaurants' | 'bars' | 'both') => {
    setShowOptionsModal(false);
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }
  
      // Use the API instance instead of direct axios call
      // This will automatically use the correct base URL and headers
      const response = await api.get('/user-profiles/generate-share-image/', {
        params: { venue_type: option }
      });
        
      if (response.data && response.data.image) {
        setImageData(response.data.image);
        setShowModal(true);
      } else {
        throw new Error('No image data in response');
      }
    } catch (error: any) {
      console.error('Error generating share image:', error);
      setError(`Failed to generate share image. ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4 sm:mt-8">
      <button
        onClick={handleShareClick}
        className="
          w-full sm:w-auto
          relative overflow-hidden
          bg-gradient-to-r from-blue-500/80 to-blue-600/80
          hover:from-blue-500/90 hover:to-blue-600/90
          text-white font-semibold
          py-2 sm:py-2.5 px-4 sm:px-5 rounded-md
          shadow-sm hover:shadow-md
          transition-all duration-300 ease-in-out
          flex items-center justify-center
          group
        "
        disabled={isLoading}
      >
        <span className="relative z-10 flex items-center">
          <Share2 className="w-4 h-4 sm:w-5 sm:h-5 mr-2 group-hover:scale-110 transition-transform duration-300" />
          <span className="text-sm sm:text-base">{isLoading ? 'Generating...' : 'Share Your Journey'}</span>
        </span>
        <span className="absolute inset-0 bg-gradient-to-r from-blue-600/20 to-blue-700/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
      </button>
      {error && <p className="text-red-500 mt-2 text-sm">{error}</p>}
      {showOptionsModal && (
        <ShareOptionsModal
          onClose={() => setShowOptionsModal(false)}
          onShare={handleShareOption}
        />
      )}
      {showModal && imageData && (
        <ImageShareModal
          imageData={imageData}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

const UserDashboard: React.FC<UserDashboardProps> = ({ userProfile }) => {
  const [recentlyVisited, setRecentlyVisited] = useState<Venue[]>([]);
  const [topRestaurantsCount, setTopRestaurantsCount] = useState(0);
  const [topBarsCount, setTopBarsCount] = useState(0);
  const [michelinStarsCount, setMichelinStarsCount] = useState(0);
  const [filter, setFilter] = useState<'ALL' | 'BAR' | 'RESTAURANT'>('ALL');
  const [userStats, setUserStats] = useState<{
    totalVisits: number;
    mostVisitedCity: string;
  }>({ totalVisits: 0, mostVisitedCity: '' });

  useEffect(() => {
    if (userProfile && Array.isArray(userProfile.visited_venues)) {
      const visited = userProfile.visited_venues;
      setRecentlyVisited(visited.slice(-5).reverse());
      setTopRestaurantsCount(visited.filter(v => v.venue_type === 'RESTAURANT').length);
      setTopBarsCount(visited.filter(v => v.venue_type === 'BAR').length);
      
      const totalStars = visited.reduce((sum, venue) => {
        const maxStars = venue.yearly_ratings
          .filter(rating => rating.publication.uses_michelin_stars)
          .reduce((max, rating) => Math.max(max, rating.michelin_stars || 0), 0);
        return sum + maxStars;
      }, 0);
      setMichelinStarsCount(totalStars);

      const totalVisits = visited.length;
      // Handle empty visits case for most visited city
      if (totalVisits === 0) {
        setUserStats({ totalVisits: 0, mostVisitedCity: 'None yet' });
      } else {
        const cityCounts = visited.reduce((acc, venue) => {
          acc[venue.city] = (acc[venue.city] || 0) + 1;
          return acc;
        }, {} as Record<string, number>);

      const mostVisitedCity = Object.entries(cityCounts).reduce((a, b) => a[1] > b[1] ? a : b)[0];

      setUserStats({ totalVisits, mostVisitedCity });
    } 
  } else {
      setRecentlyVisited([]);
      setTopRestaurantsCount(0);
      setTopBarsCount(0);
      setMichelinStarsCount(0);
      setUserStats({ totalVisits: 0, mostVisitedCity: 'N/A' });
    }
  }, [userProfile]);

  if (!userProfile) {
    return null;
  }

  const filteredVenues = recentlyVisited.filter(venue => 
    filter === 'ALL' || venue.venue_type === filter
  );

  return (
    <div className="bg-white shadow rounded-lg p-3 sm:p-6 mb-4 sm:mb-6">
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2 sm:gap-4 mb-4 sm:mb-8">
        <StatCard icon={<MapPin className="w-5 h-5 sm:w-6 sm:h-6" />} title="Total Visits" value={userStats.totalVisits} />
        <StatCard icon={<Award className="w-5 h-5 sm:w-6 sm:h-6" />} title="Top Restaurants" value={topRestaurantsCount} />
        <StatCard icon={<Martini className="w-5 h-5 sm:w-6 sm:h-6" />} title="Top Bars" value={topBarsCount} />
        <StatCard icon={<Star className="w-5 h-5 sm:w-6 sm:h-6" />} title="Michelin Stars" value={michelinStarsCount} />
        <StatCard icon={<MapPin className="w-5 h-5 sm:w-6 sm:h-6" />} title="Most Visited City" value={userStats.mostVisitedCity} />
      </div>

      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-6">
        <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-3 sm:mb-0">Recently Visited Places</h3>
        <div className="flex items-center bg-gray-100 rounded-md p-1 w-full sm:w-auto">
          <Filter className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-500" />
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value as 'ALL' | 'BAR' | 'RESTAURANT')}
            className="bg-transparent border-none text-sm w-full sm:w-auto focus:outline-none"
          >
            <option value="ALL">All</option>
            <option value="BAR">Bars</option>
            <option value="RESTAURANT">Restaurants</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 sm:gap-4">
        {filteredVenues.map((venue) => (
          <VenueCard key={venue.id} venue={venue} />
        ))}
      </div>

      <ShareImage />
    </div>
  );
};

export default UserDashboard;