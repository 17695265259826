import React, { useState } from 'react';
import { X, Utensils, Martini, Share2 } from 'lucide-react';

interface ShareOptionsModalProps {
  onClose: () => void;
  onShare: (option: 'restaurants' | 'bars' | 'both') => void;
}

const ShareOptionsModal: React.FC<ShareOptionsModalProps> = ({ onClose, onShare }) => {
  const [selectedOption, setSelectedOption] = useState<'restaurants' | 'bars' | 'both'>('both');

  const options = [
    { value: 'restaurants', icon: Utensils, label: 'Restaurants' },
    { value: 'bars', icon: Martini, label: 'Bars' },
    { value: 'both', icon: Share2, label: 'Both' },
  ];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg max-w-md w-full overflow-hidden shadow-xl">
        <div className="px-6 py-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold text-gray-900">Share Your Journey</h3>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors duration-300"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
          <p className="mb-4 text-sm text-gray-600">Choose what you'd like to showcase:</p>
          <div className="space-y-2">
            {options.map(({ value, icon: Icon, label }) => (
              <button
                key={value}
                onClick={() => setSelectedOption(value as 'restaurants' | 'bars' | 'both')}
                className={`w-full flex items-center p-3 rounded-md transition-colors duration-300 ${
                  selectedOption === value
                    ? 'bg-blue-100 text-blue-700'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                <Icon className="w-5 h-5 mr-3" />
                <span className="text-sm font-medium">{label}</span>
              </button>
            ))}
          </div>
        </div>
        <div className="bg-gray-50 px-6 py-4 flex justify-end">
          <button
            onClick={() => onShare(selectedOption)}
            className="
              relative overflow-hidden
              bg-gradient-to-r from-blue-500/80 to-blue-600/80
              hover:from-blue-500/90 hover:to-blue-600/90
              text-white font-semibold
              py-2.5 px-5 rounded-md
              shadow-sm hover:shadow-md
              transition-all duration-300 ease-in-out
              flex items-center justify-center
              group
            "
          >
            <span className="relative z-10 flex items-center">
              <Share2 className="w-5 h-5 mr-2 group-hover:scale-110 transition-transform duration-300" />
              <span>Generate Image</span>
            </span>
            <span className="absolute inset-0 bg-gradient-to-r from-blue-600/20 to-blue-700/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareOptionsModal;