import React from 'react';

const AnimatedBackground: React.FC = () => {
  return (
    <div className="fixed inset-0 z-0 overflow-hidden bg-gradient-to-br from-blue-50 via-blue-100 to-gray-200">
      <svg
        className="absolute inset-0 w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="rgba(100,149,237,0.05)" />
            <stop offset="50%" stopColor="rgba(100,149,237,0.1)" />
            <stop offset="100%" stopColor="rgba(100,149,237,0.05)" />
          </linearGradient>
          <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="rgba(100,149,237,0.03)" />
            <stop offset="50%" stopColor="rgba(100,149,237,0.07)" />
            <stop offset="100%" stopColor="rgba(100,149,237,0.03)" />
          </linearGradient>
        </defs>
        
        <path
          d="M0,200 C150,100 350,0 500,100 C650,200 850,100 1000,200"
          fill="none"
          stroke="url(#gradient1)"
          strokeWidth="1.5"
          className="animate-wave"
        />
        <path
          d="M0,250 C200,150 300,50 500,150 C700,250 800,150 1000,250"
          fill="none"
          stroke="url(#gradient2)"
          strokeWidth="1.5"
          className="animate-wave-slow"
        />
        <path
          d="M0,500 C300,400 400,300 500,400 C600,500 700,400 1000,500"
          fill="none"
          stroke="url(#gradient1)"
          strokeWidth="1.5"
          className="animate-wave-slower"
        />
        <path
          d="M0,550 C250,450 350,350 500,450 C650,550 750,450 1000,550"
          fill="none"
          stroke="url(#gradient2)"
          strokeWidth="1.5"
          className="animate-wave"
        />
        <path
          d="M0,800 C200,700 300,600 500,700 C700,800 800,700 1000,800"
          fill="none"
          stroke="url(#gradient1)"
          strokeWidth="1.5"
          className="animate-wave-slow"
        />
        <path
          d="M0,850 C150,750 350,650 500,750 C650,850 850,750 1000,850"
          fill="none"
          stroke="url(#gradient2)"
          strokeWidth="1.5"
          className="animate-wave-slower"
        />
      </svg>
    </div>
  );
};

export default AnimatedBackground;