import React from 'react';
import { Navigate } from 'react-router-dom';
import UserDashboard from './UserDashboard';
import { UserProfile } from '../services/api';

interface UserDashboardPageProps {
  userProfile: UserProfile | null;
}

const UserDashboardPage: React.FC<UserDashboardPageProps> = ({ userProfile }) => {
  if (!userProfile) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Your Dashboard</h1>
      <UserDashboard userProfile={userProfile} />
    </div>
  );
};

export default UserDashboardPage;