import React, { useState, useEffect } from 'react';
import { UserStats, fetchUserStats } from '../services/api';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, AreaChart } from 'recharts';
import { Users, UserCheck, TrendingUp, RefreshCw } from 'lucide-react';

interface StatCardProps {
  icon: React.ReactNode;
  title: string;
  value: number;
  description: string;
  trend?: number;
}

const StatCard: React.FC<StatCardProps> = ({ icon, title, value, description, trend }) => (
  <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 p-8 w-full">
    <div className="flex items-start space-x-6">
      <div className="flex-shrink-0">
        <div className="p-4 bg-blue-50 rounded-lg text-blue-600">
          {icon}
        </div>
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-base font-medium text-gray-500 truncate mb-2">{title}</p>
        <div className="flex items-baseline mb-2">
          <p className="text-3xl font-semibold text-gray-900 mr-3">{value.toLocaleString()}</p>
          {trend !== undefined && (
            <span className={`text-sm font-medium ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              {trend >= 0 ? '+' : ''}{trend}%
            </span>
          )}
        </div>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    </div>
  </div>
);

const AdminDashboard: React.FC = () => {
  const [stats, setStats] = useState<UserStats | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const loadStats = async () => {
    try {
      setIsRefreshing(true);
      const data = await fetchUserStats();
      setStats(data);
      setError(null);
    } catch (err) {
      setError('Failed to load user statistics');
      console.error('Error loading user stats:', err);
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    loadStats();
    const interval = setInterval(loadStats, 300000);
    return () => clearInterval(interval);
  }, []);

  const calculateTrend = () => {
    if (!stats?.monthly_signups || stats.monthly_signups.length < 2) return 0;
    const lastTwo = stats.monthly_signups.slice(-2);
    const prevMonth = lastTwo[0].count;
    const currentMonth = lastTwo[1].count;
    return prevMonth === 0 ? 100 : Math.round(((currentMonth - prevMonth) / prevMonth) * 100);
  };

  const cumulativeData = stats?.monthly_signups.map((item, index, array) => {
    const totalUsers = array
      .slice(0, index + 1)
      .reduce((sum, curr) => sum + curr.count, 0);
    return {
      month: item.month,
      'Total Users': totalUsers
    };
  }) || [];

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 bg-red-50 p-6 rounded-lg shadow">
          <p className="font-medium">{error}</p>
          <button 
            onClick={loadStats}
            className="mt-3 text-sm text-red-600 hover:text-red-700"
          >
            Try again
          </button>
        </div>
      </div>
    );
  }

  if (!stats) return null;

  return (
    <div className="container mx-auto px-6 py-12 max-w-[1400px]">
      <div className="flex justify-between items-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900">Analytics Dashboard</h1>
        <button
          onClick={loadStats}
          disabled={isRefreshing}
          className={`flex items-center px-5 py-2.5 rounded-lg text-sm font-medium text-blue-600 bg-blue-50 hover:bg-blue-100 transition-colors duration-200 ${
            isRefreshing ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <RefreshCw className={`w-4 h-4 mr-2 ${isRefreshing ? 'animate-spin' : ''}`} />
          Refresh Data
        </button>
      </div>
      
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3 mb-12">
        <StatCard
          icon={<Users className="h-7 w-7" />}
          title="Total Users"
          value={stats.total_users}
          description="All registered users"
          trend={calculateTrend()}
        />
        <StatCard
          icon={<UserCheck className="h-7 w-7" />}
          title="Active Users"
          value={stats.active_users_30d}
          description="Active in last 30 days"
        />
        <StatCard
          icon={<TrendingUp className="h-7 w-7" />}
          title="New Users"
          value={stats.monthly_signups[stats.monthly_signups.length - 1]?.count || 0}
          description="Joined this month"
        />
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
        <div className="bg-white rounded-xl shadow-sm p-8 min-h-[500px]">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">Monthly User Signups</h2>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={stats.monthly_signups}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis 
                  dataKey="month" 
                  tickFormatter={(value) => {
                    const date = new Date(value);
                    return date.toLocaleDateString('default', { month: 'short' });
                  }}
                />
                <YAxis />
                <Tooltip 
                  formatter={(value: number) => [value.toLocaleString(), 'New Users']}
                  labelFormatter={(label) => {
                    const date = new Date(label);
                    return date.toLocaleDateString('default', { month: 'long', year: 'numeric' });
                  }}
                />
                <Area 
                  type="monotone" 
                  dataKey="count"
                  stroke="#3b82f6"
                  fill="#93c5fd"
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm p-8 min-h-[500px]">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">Total User Growth</h2>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={cumulativeData}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis 
                  dataKey="month" 
                  tickFormatter={(value) => {
                    const date = new Date(value);
                    return date.toLocaleDateString('default', { month: 'short' });
                  }}
                />
                <YAxis />
                <Tooltip 
                  formatter={(value: number) => [value.toLocaleString(), 'Total Users']}
                  labelFormatter={(label) => {
                    const date = new Date(label);
                    return date.toLocaleDateString('default', { month: 'long', year: 'numeric' });
                  }}
                />
                <Area 
                  type="monotone" 
                  dataKey="Total Users"
                  stroke="#2563eb"
                  fill="#60a5fa"
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;