import React from 'react';
import { Search, X } from 'lucide-react';

interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
}

const SearchBar = ({ value, onChange, placeholder = 'Search venues...', className = '' }: SearchBarProps) => {
  return (
    <div className={`relative flex items-center ${className}`}>
      <Search className="absolute left-3 h-4 w-4 text-gray-400" />
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="
          w-full
          pl-9 pr-8
          py-2
          bg-white
          border border-gray-200
          rounded-lg
          text-sm
          placeholder-gray-400
          focus:outline-none
          focus:ring-2
          focus:ring-blue-500/20
          focus:border-blue-500/20
          transition-all
          duration-200
        "
      />
      {value && (
        <button
          onClick={() => onChange('')}
          className="
            absolute
            right-3
            p-0.5
            rounded-full
            text-gray-400
            hover:text-gray-600
            hover:bg-gray-100
            transition-all
            duration-200
          "
        >
          <X className="h-3 w-3" />
        </button>
      )}
    </div>
  );
};

export default SearchBar;